import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ContactForm } from "@/components/contact-form";
import { Tabs } from "antd";
import { Timeline } from "@/components/timeline";
import { Arrow } from "@/components/arrow";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";
import { Layout } from "@/components/layout";
import {
  SiGradle,
  SiReact,
  SiFlutter,
  SiIonic,
  SiKotlin,
  SiFirebase,
  SiRealm,
  // SiJava,
  SiDotnet,
  SiJavascript,
  SiRubyonrails,
  SiPython,
  SiXamarin,
  SiSwift,
} from "react-icons/si";
import { FaJava } from "react-icons/fa";
import { GiJetPack } from "react-icons/gi";

const MobileAppDevelopment = () => {
  return (
    <>
      <GatsbySeo
        title="Best mobile app development Company in Chennai | Android App Developer"
        description="We are Best Mobile app development Company in Chennai. As well as we are Professional Android App Developer Providing Top Services."
        canonical="https://www.tectratechnologies.com/mobile-app-development/"
        openGraph={{
          locale: "en_US",
          type: "article",
          url: "https://www.tectratechnologies.com/mobile-app-development/",
          title:
            "Best Web development Company in Chennai | Android App Developer",
          description:
            "We are Best Mobile app development Company in Chennai. As well as we are Professional Android App Developer Providing Top Services.",
          site_name: "Tectra Technologies",
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
            {
              "@type": "WebPage",
              "@id":
                "https://www.tectratechnologies.com/mobile-app-development/",
              url: "https://www.tectratechnologies.com/mobile-app-development/",
              name: "Best Mobile app development Company in Chennai | Android App Developer",
              isPartOf: {
                "@id": "https://www.tectratechnologies.com/#website",
              },
              datePublished: "2019-06-18T09:32:45+00:00",
              dateModified: "2022-05-10T17:38:52+00:00",
              description:
                "We are Best Mobile app development Company in Chennai. As well as we are Professional Android App Developer Providing Top Services.",
              breadcrumb: {
                "@id":
                  "https://www.tectratechnologies.com/mobile-app-development/#breadcrumb",
              },
              inLanguage: "en-US",
              potentialAction: [
                {
                  "@type": "ReadAction",
                  target: [
                    "https://www.tectratechnologies.com/mobile-app-development/",
                  ],
                },
              ],
            },
            {
              "@type": "BreadcrumbList",
              "@id":
                "https://www.tectratechnologies.com/mobile-app-development/#breadcrumb",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Home",
                  item: "https://www.tectratechnologies.com/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Mobile App Development",
                },
              ],
            },
          ],
        }}
      />
      <Layout>
        <div className="bg-white h-screen flex items-center justify-center">
          <div className="w-full max-w-7xl mx-auto px-5 lg:px-8">
            <div className="text-left xl:w-3/5">
              <div className="">
                <h1 className="text-black text-4xl font-poppins font-semibold mb-4 normal-case leading-relaxed">
                  High-tech custom apps to build your business.
                </h1>
                <div className="relative flex overflow-hidden justify-start items-center text-center">
                  <Link
                    to="/contact"
                    className="flex items-center text-xl normal-case hover:text-black"
                    activeClassName="border-b-2 border-black"
                  >
                    Talk to us
                    <Arrow color="black" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative overflow-hidden bg-white mt-10 lg:mt-12">
          <div className="mx-auto max-w-7xl px-0 lg:px-8 relative">
            <div className="grid grid-cols-1 items-center justify-center lg:gap-y-2 lg:grid-cols-12">
              <div className="flex flex-wrap items-center justify-center pb-4 lg:pr-12 sm:pb-0 lg:col-span-6 order-2 lg:order-1">
                <div className="relative z-10 gap-x-4 px-5 lg:px-0 lg:block lg:gap-x-0 lg:gap-y-1">
                  <p className="text-base text-black font-poppins font-thin leading-8 mb-0">
                    Leverage an exceptional experience in custom mobile app
                    development and let create a seamless exposure for your
                    users!
                  </p>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4 mb-0">
                    Whether you are aiming for your business to have a humungous
                    reach or looking forward to improving your current mobile
                    app; all you need is a skillful team, like ours, who has
                    mastered making such successful mobile applications that
                    perfectly hits the targeted market. We have mastered our
                    in-house team in delivering multiple mobile applications
                    that are users friendly and intuitive. Even if your
                    operations appear highly complicated, our expertise will
                    mold them into a seamless mobile application for your
                    end-users. Let's peep into the solutions that we provide you
                    with.
                  </p>
                </div>
              </div>
              <div className="lg:col-span-6 order-1 lg:order-2 mb-2 lg:mb-2">
                <div className="relative w-auto overflow-hidden lg:mt-0 lg:w-[67.8125rem]">
                  <div className="w-full h-full">
                    <StaticImage
                      src="../../images/Mobile-app-development1.png"
                      alt=""
                      layout="constrained"
                      placeholder="blurred"
                      quality={95}
                      height={250}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                  <div className="w-full h-full mt-4">
                    <StaticImage
                      src="../../images/daniel-korpai-mxPiMiz7KCo-unsplash1.png"
                      alt=""
                      layout="constrained"
                      placeholder="blurred"
                      quality={95}
                      height={250}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize">
              PROCESS
            </h2>
            <div className="mt-6 ml-2 lg:ml-0">
              <Timeline
                items={[
                  "First Discussion",
                  "Requirement Analysis, timeline & cost estimation",
                  "The Design, Conception & Planning",
                  "The Development & the Testing Phase",
                  "Deployment & Post productioon phase",
                ]}
              />
            </div>
          </div>
        </div>

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-center text-2xl font-poppins font-semibold captalize">
              What can we assist you with?
            </h2>
            <div className="mt-6">
              <p className="text-base font-poppins font-thin leading-8">
                We have skilled in-house experts, who will dive deeply into your
                business to provide you with end-to-end solutions. With a team
                of professionals, we help you with:
              </p>
              <ul className="text-base font-poppins font-thin list-disc leading-8">
                <li className="mt-2">
                  <span className="font-medium">Mobile Consulting</span>
                  <br />
                  Our all-rounder consultant team aims at guiding mobile
                  application development initiatives. We advise on the
                  compatibility, the functionality of existing applications, or
                  the creation of new applications from scratch.
                </li>
                <li className="mt-2">
                  <span className="font-medium">Research & Analysis</span>
                  <br />
                  Well-versed in different industries, our business analysis
                  deeply analyses the concept and assesses its feasibility,
                  market potential, technology complexity, and so forth factors
                  to ensure you a clear picture.
                </li>
                <li className="mt-2">
                  <span className="font-medium">High Confidentiality</span>{" "}
                  <br />
                  Your security is our priority and this is why our team makes
                  sure that all your sensitive data are completely safe.
                </li>
                <li className="mt-2">
                  <span className="font-medium">App Designing</span> <br />
                  Our UX & UI exports deliver responsive and fascinating designs
                  to ensure high conversion and easy adoption. The design team
                  works with magic to make every complex functionality perfectly
                  fit on your user's mobile screen.
                </li>
                <li className="mt-2">
                  <span className="font-medium">Creating Strategies</span>{" "}
                  <br />
                  Our team works day in and day out to get an in-depth
                  understanding of market analytics that further generates
                  successful mobile strategy for your organization. We use
                  custom mobile app development services that help you in
                  getting predictably successful results.
                </li>
                <li className="mt-2">
                  <span className="font-medium">Meeting Business Goals</span>{" "}
                  <br />
                  With our assistance and premium quality digital tools, we can
                  fully meet customers' needs and accelerate business growth. In
                  addition, our team of experts creates a completed cycle from
                  idea to launch.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-5xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-center text-2xl font-poppins font-semibold captalize">
              Mobile Development Expertise
            </h2>
            <div className="mt-8 font-poppins font-semibold text-lg text-center">
              <Tabs defaultActiveKey="1" centered={true}>
                <Tabs.TabPane
                  tab="IOS App"
                  key="1"
                  className="font-poppins font-normal text-black text-sm"
                >
                  <div className="flex flex-wrap text-left text-base font-poppins font-thin">
                    <p className="text-base font-poppins font-thin leading-8">
                      Tectra possesses knowledge and skills to build reliable,
                      scalable, and feature-rich iOS mobile apps, including:
                    </p>
                    <ul className="text-base font-poppins font-thin list-disc ml-4 leading-8">
                      <li className="mt-2">iPhone App Development</li>
                      <li className="mt-2"> iPad App Development</li>
                      <li className="mt-2">Apple Watch App Development</li>
                      <li className="mt-2">Apple TV App Development</li>
                      <li className="mt-2">App Clips Development</li>
                    </ul>
                  </div>
                  <div className="mt-8 grid grid-cols-3 gap-12 md:grid-cols-4 lg:grid-cols-6">
                    <div className="col-span-1 flex p-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <SiFirebase className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          Firebase
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex p-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 66 66"
                          width="1.1em"
                          height="1.1em"
                          className="mx-auto text-5xl"
                          fill="#000000"
                          fill-rule="evenodd"
                          stroke="#000000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <use xlinkHref="#A" x=".5" y=".5" />
                          <symbol id="A" overflow="visible">
                            <path
                              d="M31.912 64C14.717 64 .135 49.476.001 32.17A31.54 31.54 0 0 1 9.214 9.665 32.07 32.07 0 0 1 31.996 0a31.62 31.62 0 0 1 22.447 9.322A31.68 31.68 0 0 1 64 31.702C64.08 49.4 49.858 63.82 32.172 64zm.1-62.325a30.4 30.4 0 0 0-21.584 9.172 29.88 29.88 0 0 0-8.753 21.308c.126 16.4 13.946 30.153 30.228 30.153h.218c16.77-.152 30.268-13.825 30.203-30.597-.076-7.992-3.338-15.622-9.063-21.2a29.85 29.85 0 0 0-21.249-8.837zM7.18 41.36V22.665h4.548v1.675H9.315v15.378h2.412v1.675zm15.435-9.532c0 3.4-2.07 5.352-4.875 5.352s-4.674-2.186-4.674-5.176c0-3.124 1.96-5.327 4.824-5.327s4.724 2.295 4.724 5.15zm-7.588.15c0 2.07 1.04 3.7 2.823 3.7s2.806-1.675 2.806-3.786c0-1.918-.946-3.727-2.806-3.727S15 29.927 15 32.02zM24.2 27.03a15.51 15.51 0 0 1 2.697-.226c1.015-.1 2.036.127 2.915.645.67.395 1.075 1.123 1.055 1.9a2.36 2.36 0 0 1-1.675 2.203c1.195.243 2.053 1.294 2.052 2.513a2.64 2.64 0 0 1-.98 2.094c-.72.637-1.918.946-3.77.946a17.92 17.92 0 0 1-2.278-.134zm1.843 4.012h.946c1.28 0 2.002-.603 2.002-1.466s-.72-1.4-1.9-1.4a5.04 5.04 0 0 0-1.055.075zm0 4.674c.326.034.653.048.98.042 1.2 0 2.245-.452 2.245-1.675s-1.022-1.675-2.303-1.675h-.92zm8.85-8.82h1.85v6.6c0 2.806-1.357 3.744-3.35 3.744a4.95 4.95 0 0 1-1.566-.268l.226-1.5a4.06 4.06 0 0 0 1.147.184c1.055 0 1.675-.486 1.675-2.245zm7.12 5.503v1.282H38.16V32.4zM51 36.753c-.833.332-1.726.486-2.622.452-3.225 0-5.185-2.02-5.185-5.1a5.11 5.11 0 0 1 5.427-5.369 5.88 5.88 0 0 1 2.513.452l-.4 1.474a4.95 4.95 0 0 0-1.993-.394c-2.07 0-3.56 1.298-3.56 3.744 0 2.228 1.315 3.67 3.543 3.67a5.17 5.17 0 0 0 2.02-.377zm5.812-14.138V41.36h-4.548v-1.675h2.412V24.3h-2.412v-1.675z"
                              stroke="none"
                              fill="#000000"
                              fill-rule="nonzero"
                            />
                          </symbol>
                        </svg>
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          Objective C
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <SiRealm className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          Realm
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <svg
                          width="2.5rem"
                          height="2.5rem"
                          className="mx-auto text-5xl"
                          viewBox="0 0 256 247"
                          version="1.1"
                          fill="#000000"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          preserveAspectRatio="xMidYMid"
                        >
                          <defs>
                            <radialGradient
                              cx="78.7636112%"
                              cy="37.8476394%"
                              fx="78.7636112%"
                              fy="37.8476394%"
                              r="89.8725577%"
                              id="radialGradient-1"
                            >
                              <stop stop-color="#000000" offset="0%"></stop>
                              <stop stop-color="#000000" offset="100%"></stop>
                            </radialGradient>
                            <radialGradient
                              cx="68.7389016%"
                              cy="4.39833672%"
                              fx="68.7389016%"
                              fy="4.39833672%"
                              r="81.7284786%"
                              id="radialGradient-2"
                            >
                              <stop stop-color="#000000" offset="0%"></stop>
                              <stop
                                stop-color="#000000"
                                offset="29.1746283%"
                              ></stop>
                              <stop stop-color="#000000" offset="100%"></stop>
                            </radialGradient>
                            <linearGradient
                              x1="18.2386532%"
                              y1="0%"
                              x2="81.1591125%"
                              y2="84.3374763%"
                              id="linearGradient-3"
                            >
                              <stop stop-color="#000000" offset="0%"></stop>
                              <stop
                                stop-color="#000000"
                                offset="66.9712865%"
                              ></stop>
                              <stop
                                stop-color="#000000"
                                stop-opacity="0.2"
                                offset="82.7147533%"
                              ></stop>
                              <stop
                                stop-color="#C10090"
                                stop-opacity="0"
                                offset="100%"
                              ></stop>
                            </linearGradient>
                            <linearGradient
                              x1="64.9060589%"
                              y1="71.5585538%"
                              x2="44.2897699%"
                              y2="50%"
                              id="linearGradient-4"
                            >
                              <stop
                                stop-color="#000000"
                                stop-opacity="0.151340138"
                                offset="0%"
                              ></stop>
                              <stop
                                stop-color="#000000"
                                stop-opacity="0.4"
                                offset="40.0350765%"
                              ></stop>
                              <stop
                                stop-color="#000000"
                                stop-opacity="0.891668"
                                offset="64.8995536%"
                              ></stop>
                              <stop stop-color="#000000" offset="100%"></stop>
                            </linearGradient>
                          </defs>
                          <g>
                            <path
                              d="M16.6852208,157.125328 C3.56690702,87.3798324 38.2363025,20.1145078 117.808706,11.1662199 C106.835616,-0.558801732 91.8452087,-0.646905628 84.9481697,0.779380087 C72.770288,4.66044372 73.1525932,12.540855 59.3390152,22.7199675 C45.6064437,30.5634307 38.7094156,24.5568182 28.7057455,32.6879515 C18.7234849,40.7583874 25.6888528,59.2851732 21.5022823,62.8870857 C17.3464381,70.0905489 4.45500952,76.5077264 2.10834286,85.6062545 C0.168948918,97.2420641 7.37241212,105.553752 7.09535584,115.527778 C7.92652468,123.839467 -1.17920693,128.539449 0.129052814,135.275796 C4.0477368,146.281025 11.600845,152.904887 15.1615723,155.958047 C15.9781085,156.533531 16.8404881,157.95083 16.6852208,157.125328 L16.6852208,157.125328 Z"
                              fill="#000000"
                            ></path>
                            <path
                              d="M158.275491,60.578542 C155.368486,60.578542 153.011422,58.2214776 153.011422,55.3144727 C153.011422,52.4074679 155.368486,50.0504035 158.275491,50.0504035 C161.182496,50.0504035 163.53956,52.4074679 163.53956,55.3144727 C163.53956,58.2214776 161.182496,60.578542 158.275491,60.578542 L158.275491,60.578542 Z M19.7566405,164.732808 C7.1500258,104.116773 46.1602355,53.4676156 121.704062,78.4026805 C166.031404,104.334594 221.793282,102.646102 224.307422,85.8832 C230.514061,65.7878769 196.047681,24.3767065 144.515214,13.5715117 C42.2814476,-6.37654026 -12.8335943,104.116774 19.7566405,164.732808 L19.7566405,164.732808 Z"
                              fill="url(#radialGradient-1)"
                            ></path>
                            <path
                              d="M187.458604,171.493257 C202.639072,173.137863 217.048769,169.494573 230.402327,158.61014 C210.228197,181.112651 185.002777,192.426521 156.059262,195.505171 C169.878829,207.254019 183.20579,212.546348 195.955366,210.281136 C160.528734,220.05679 130.847947,209.296529 94.7424273,173.340673 C92.8517347,183.020022 103.074741,198.100667 113.611745,207.727264 C52.4742909,181.221845 47.1143627,98.6544556 121.66531,78.3442237 C44.3844415,41.214641 0.686373501,113.357693 22.1558444,172.485931 C43.1623368,218.026693 99.1402667,253.085223 160.492163,245.3753 C190.292928,241.7251 234.79401,221.178935 252.973664,172.485931 C240.160919,183.983766 217.257941,193.997836 207.037617,194.765984 C241.628648,177.478781 260.301586,148.103896 255.060336,107.955387 C247.895106,125.013742 238.441392,138.114625 226.616076,147.112305 C251.735653,107.955387 247.425219,87.716426 228.832526,65.4732398 C242.131228,102.044668 224.928249,142.633967 187.458604,171.493257 L187.458604,171.493257 Z"
                              fill="url(#radialGradient-2)"
                            ></path>
                            <path
                              d="M169.707072,213.625541 C167.082407,213.13513 175.656929,217.098842 159.079366,212.710316 C142.501804,208.32179 125.622502,204.092744 94.7424273,173.340673 C92.8517347,183.020022 103.074741,198.100667 113.611745,207.727264 C142.056275,227.564927 122.711866,218.286797 166.051946,233.269481 C169.52976,226.346862 169.707072,220.195346 169.707072,213.625541 L169.707072,213.625541 Z"
                              fill="url(#linearGradient-3)"
                            ></path>
                            <path
                              d="M114.601372,57.8510108 C114.601372,57.8510108 118.369452,52.2893628 119.836219,49.7810251 C121.633641,46.7072319 124.393939,41.104618 124.393939,41.104618 C124.393939,41.104618 95.389611,31.6417749 88.2716448,30.4871665 C66.1450215,36.2308801 66.0645022,45.5009559 78.435065,59.690116 C79.8114806,61.2693368 114.601372,57.8510108 114.601372,57.8510108 L114.601372,57.8510108 Z"
                              fill="url(#linearGradient-4)"
                            ></path>
                          </g>
                        </svg>
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          RxSwift
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <SiSwift className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          SwiftWeb Socket
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="2.5rem"
                          height="2.5rem"
                          className="mx-auto text-5xl"
                          viewBox="0 0 306 344.35"
                          enable-background="new 0 0 306 344.35"
                          xmlSpace="preserve"
                        >
                          <path
                            fill="#000000"
                            d="M302.107,258.262c2.401-4.159,3.893-8.845,3.893-13.053V99.14c0-4.208-1.49-8.893-3.892-13.052L153,172.175
	L302.107,258.262z"
                          />
                          <path
                            fill="#000000"
                            d="M166.25,341.193l126.5-73.034c3.644-2.104,6.956-5.737,9.357-9.897L153,172.175L3.893,258.263
	c2.401,4.159,5.714,7.793,9.357,9.896l126.5,73.034C147.037,345.401,158.963,345.401,166.25,341.193z"
                          />
                          <path
                            fill="#000000"
                            d="M302.108,86.087c-2.402-4.16-5.715-7.793-9.358-9.897L166.25,3.156c-7.287-4.208-19.213-4.208-26.5,0
	L13.25,76.19C5.962,80.397,0,90.725,0,99.14v146.069c0,4.208,1.491,8.894,3.893,13.053L153,172.175L302.108,86.087z"
                          />
                          <g>
                            <path
                              fill="#FFFFFF"
                              d="M153,274.175c-56.243,0-102-45.757-102-102s45.757-102,102-102c36.292,0,70.139,19.53,88.331,50.968
		l-44.143,25.544c-9.105-15.736-26.038-25.512-44.188-25.512c-28.122,0-51,22.878-51,51c0,28.121,22.878,51,51,51
		c18.152,0,35.085-9.776,44.191-25.515l44.143,25.543C223.142,254.644,189.294,274.175,153,274.175z"
                            />
                          </g>
                          <g>
                            <polygon
                              fill="#FFFFFF"
                              points="255,166.508 243.666,166.508 243.666,155.175 232.334,155.175 232.334,166.508 221,166.508 
		221,177.841 232.334,177.841 232.334,189.175 243.666,189.175 243.666,177.841 255,177.841 	"
                            />
                          </g>
                          <g>
                            <polygon
                              fill="#FFFFFF"
                              points="297.5,166.508 286.166,166.508 286.166,155.175 274.834,155.175 274.834,166.508 263.5,166.508 
		263.5,177.841 274.834,177.841 274.834,189.175 286.166,189.175 286.166,177.841 297.5,177.841 	"
                            />
                          </g>
                        </svg>

                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          C++
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <FaJava className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          Java
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <svg
                          fill="none"
                          width="2.5rem"
                          height="2.5rem"
                          className="mx-auto text-5xl"
                          viewBox="204.925 153.577 757.617 860.543"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="m962.541 407.983c0-14.237-3.034-27.074-9.336-37.811-6.068-10.736-15.171-19.605-27.307-26.607-100.829-58.117-201.891-116.233-302.72-174.35-27.308-15.638-53.449-15.171-80.523.7-40.145 23.574-241.336 138.873-301.086 173.65-24.741 14.237-36.644 36.177-36.644 64.652v351.033c0 14.004 3.034 26.374 8.869 37.111 6.069 10.97 15.405 20.306 27.775 27.541 59.984 34.777 260.941 149.843 301.086 173.65 27.074 15.868 53.448 16.568 80.523.7 100.829-58.35 201.891-116.233 302.72-174.35 12.603-7.235 21.706-16.338 27.774-27.541 5.835-10.737 8.869-23.107 8.869-37.111z"
                            fill="#000000"
                          />
                          <path
                            d="m584.667 582.333-371.34 213.794c6.069 10.97 15.405 20.306 27.775 27.542 59.984 34.776 260.941 149.842 301.086 173.649 27.074 15.872 53.449 16.572 80.523.7 100.829-58.35 201.891-116.233 302.72-174.349 12.603-7.236 21.706-16.338 27.774-27.542z"
                            fill="#000000"
                          />
                          <path
                            d="m474.735 645.584c21.707 37.811 62.318 63.252 108.765 63.252 46.913 0 87.758-25.674 109.231-63.952l-108.064-62.551z"
                            fill="#000000"
                          />
                          <path
                            d="m962.542 407.983c0-14.237-3.035-27.074-9.336-37.811l-368.539 212.161 369.005 213.795c5.835-10.737 8.87-23.107 8.87-37.111z"
                            fill="#000000"
                          />
                          <g fill="#fff">
                            <path d="m692.731 644.884c-21.472 38.044-62.317 63.952-109.231 63.952-46.68 0-87.291-25.441-108.764-63.252-10.503-18.438-16.572-39.444-16.572-62.084 0-69.32 56.016-125.336 125.336-125.336 46.213 0 86.592 25.207 108.298 62.551l109.464-63.018c-43.645-75.154-124.869-125.802-217.995-125.802-139.107 0-251.605 112.732-251.605 251.605 0 45.513 12.136 88.459 33.376 125.336 43.412 75.388 125.102 126.269 218.462 126.269 93.594 0 175.284-51.114 218.696-126.969zm138.406-120.901h-24.974v120.668h24.974zm55.316 0h-24.974v120.668h24.974z" />
                            <path d="m906.759 544.289h-120.668v24.974h120.668zm0 55.082h-120.668v24.974h120.668z" />
                          </g>
                        </svg>
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          C#
                        </h4>
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Android App" key="2">
                  <div className="flex flex-wrap text-left text-base text-black  font-poppins font-thin leading-8">
                    <p className="text-left text-base text-black font-poppins font-thin leading-8">
                      With Tectra’s development team, your envisioned solution
                      will function seamlessly and properly on any of the
                      Android-based devices. Ensure the best possible
                      performance and UX with us:
                    </p>
                    <ul className="list-disc ml-4 text-base text-black text-left font-poppins font-thin leading-8">
                      <li className="mt-2">Android Mobile App Development</li>
                      <li className="mt-2">Android TV App Development</li>
                      <li className="mt-2">Android Tablet App Development</li>
                      <li className="mt-2">Apple TV App Development</li>
                      <li className="mt-2">Android Wear App Development</li>
                    </ul>
                  </div>
                  <div className="mt-8 grid grid-cols-3 gap-12 md:grid-cols-4 lg:grid-cols-6">
                    <div className="col-span-1 flex p-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <GiJetPack className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          JetPack
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex p-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <SiFirebase className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          Firebase
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <SiGradle className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          Gradle
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <FaJava className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          Java
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <SiKotlin className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          Kotlin
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <SiSwift className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          Swift Web Socket
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <SiRealm className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          Realm
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <FaJava className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          RxJava
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <svg
                          width="2.5rem"
                          height="2.5rem"
                          className="mx-auto text-5xl"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 38.000089 42.000031"
                          version="1.1"
                          id="svg10"
                        >
                          <path
                            fill="#000000"
                            fill-rule="evenodd"
                            d="m 17.903,0.28628166 c 0.679,-0.381 1.515,-0.381 2.193,0 C 23.451,2.1692817 33.547,7.8372817 36.903,9.7202817 37.582,10.100282 38,10.804282 38,11.566282 c 0,3.766 0,15.101 0,18.867 0,0.762 -0.418,1.466 -1.097,1.847 -3.355,1.883 -13.451,7.551 -16.807,9.434 -0.679,0.381 -1.515,0.381 -2.193,0 -3.355,-1.883 -13.451,-7.551 -16.807,-9.434 -0.678,-0.381 -1.096,-1.084 -1.096,-1.846 0,-3.766 0,-15.101 0,-18.867 0,-0.762 0.418,-1.466 1.097,-1.8470003 3.354,-1.883 13.452,-7.551 16.806,-9.43400004 z"
                            clip-rule="evenodd"
                            id="path2"
                          />
                          <path
                            fill="#000000"
                            fill-rule="evenodd"
                            d="m 0.304,31.404282 c -0.266,-0.356 -0.304,-0.694 -0.304,-1.149 0,-3.744 0,-15.014 0,-18.759 0,-0.758 0.417,-1.458 1.094,-1.8360003 3.343,-1.872 13.405,-7.507 16.748,-9.38000004 0.677,-0.379 1.594,-0.371 2.271,0.008 3.343,1.87200004 13.371,7.45900004 16.714,9.33100004 0.27,0.152 0.476,0.335 0.66,0.5760003 z"
                            clip-rule="evenodd"
                            id="path4"
                          />
                          <path
                            fill="#ffffff"
                            fill-rule="evenodd"
                            d="m 19,7.0002817 c 7.727,0 14,6.2730003 14,14.0000003 0,7.727 -6.273,14 -14,14 -7.727,0 -14,-6.273 -14,-14 0,-7.727 6.273,-14.0000003 14,-14.0000003 z m 0,7.0000003 c 3.863,0 7,3.136 7,7 0,3.863 -3.137,7 -7,7 -3.863,0 -7,-3.137 -7,-7 0,-3.864 3.136,-7 7,-7 z"
                            clip-rule="evenodd"
                            id="path6"
                          />
                          <path
                            fill="#000000"
                            fill-rule="evenodd"
                            d="m 37.485,10.205282 c 0.516,0.483 0.506,1.211 0.506,1.784 0,3.795 -0.032,14.589 0.009,18.384 0.004,0.396 -0.127,0.813 -0.323,1.127 l -19.084,-10.5 z"
                            clip-rule="evenodd"
                            id="path8"
                          />
                        </svg>
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          C
                        </h4>
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Cross-Platform App" key="3">
                  <div className="flex flex-wrap text-left text-base font-poppins font-thin">
                    <p className="text-base text-black text-left font-poppins font-thin leading-8">
                      Ensure the optimal cross-platform experience and user
                      satisfaction for your solution with Andersen's app
                      developers. Our expertise and experience are the best
                      guarantees of obtaining a feature-rich, reliable,
                      top-performing, and highly adjustable cross-platform
                      application for a wide range of popular devices:
                    </p>
                    <ul className="list-disc ml-4 text-black text-base text-left font-poppins font-thin leading-8">
                      <li className="mt-2">Hybrid Mobile App Development</li>
                      <li className="mt-2">Hybrid Tablet App Development</li>
                      <li className="mt-2">Hybrid TV App Development</li>
                    </ul>
                  </div>
                  <div className="mt-8 grid grid-cols-3 gap-12 md:grid-cols-4 lg:grid-cols-6">
                    <div className="col-span-1 flex p-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <SiPython className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          Python
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex p-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <SiRubyonrails className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          Ruby On Rails
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <SiDotnet className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          .Net
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <SiFlutter className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          Flutter
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <SiReact className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          React Native
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <SiXamarin className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          Xamarin
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <SiIonic className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          Ionic
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <SiJavascript className="mx-auto text-5xl" />
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          Javascript
                        </h4>
                      </div>
                    </div>
                    <div className="col-span-1 flex py-3 px-2 justify-center md:col-span-2 lg:col-span-1">
                      <div className="text-center">
                        <svg
                          fill="none"
                          width="2.5rem"
                          height="2.5rem"
                          className="mx-auto text-5xl"
                          viewBox="204.925 153.577 757.617 860.543"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="m962.541 407.983c0-14.237-3.034-27.074-9.336-37.811-6.068-10.736-15.171-19.605-27.307-26.607-100.829-58.117-201.891-116.233-302.72-174.35-27.308-15.638-53.449-15.171-80.523.7-40.145 23.574-241.336 138.873-301.086 173.65-24.741 14.237-36.644 36.177-36.644 64.652v351.033c0 14.004 3.034 26.374 8.869 37.111 6.069 10.97 15.405 20.306 27.775 27.541 59.984 34.777 260.941 149.843 301.086 173.65 27.074 15.868 53.448 16.568 80.523.7 100.829-58.35 201.891-116.233 302.72-174.35 12.603-7.235 21.706-16.338 27.774-27.541 5.835-10.737 8.869-23.107 8.869-37.111z"
                            fill="#000000"
                          />
                          <path
                            d="m584.667 582.333-371.34 213.794c6.069 10.97 15.405 20.306 27.775 27.542 59.984 34.776 260.941 149.842 301.086 173.649 27.074 15.872 53.449 16.572 80.523.7 100.829-58.35 201.891-116.233 302.72-174.349 12.603-7.236 21.706-16.338 27.774-27.542z"
                            fill="#000000"
                          />
                          <path
                            d="m474.735 645.584c21.707 37.811 62.318 63.252 108.765 63.252 46.913 0 87.758-25.674 109.231-63.952l-108.064-62.551z"
                            fill="#000000"
                          />
                          <path
                            d="m962.542 407.983c0-14.237-3.035-27.074-9.336-37.811l-368.539 212.161 369.005 213.795c5.835-10.737 8.87-23.107 8.87-37.111z"
                            fill="#000000"
                          />
                          <g fill="#fff">
                            <path d="m692.731 644.884c-21.472 38.044-62.317 63.952-109.231 63.952-46.68 0-87.291-25.441-108.764-63.252-10.503-18.438-16.572-39.444-16.572-62.084 0-69.32 56.016-125.336 125.336-125.336 46.213 0 86.592 25.207 108.298 62.551l109.464-63.018c-43.645-75.154-124.869-125.802-217.995-125.802-139.107 0-251.605 112.732-251.605 251.605 0 45.513 12.136 88.459 33.376 125.336 43.412 75.388 125.102 126.269 218.462 126.269 93.594 0 175.284-51.114 218.696-126.969zm138.406-120.901h-24.974v120.668h24.974zm55.316 0h-24.974v120.668h24.974z" />
                            <path d="m906.759 544.289h-120.668v24.974h120.668zm0 55.082h-120.668v24.974h120.668z" />
                          </g>
                        </svg>
                        <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                          C#
                        </h4>
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default MobileAppDevelopment;
