import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ContactForm } from "@/components/contact-form";
import { Layout } from "@/components/layout";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";

const ContactPage = () => {
  return (
    <>
      <GatsbySeo
        title="Contact Us | Tectra Technologies"
        description="Tectra Technologies produces and delivers the right solution to a vast array of demands in the digital atmosphere and in software development"
        canonical="https://www.tectratechnologies.com/contact/"
        openGraph={{
          locale: "en_US",
          type: "article",
          url: "https://www.tectratechnologies.com/contact/",
          title: "Contact Us - Tectra Technologies",
          description:
            "Tectra Technologies produces and delivers the right solution to a vast array of demands in the digital atmosphere and in software development",
          site_name: "Tectra Technologies"
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image"
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}"
                  },
                  "query-input": "required name=search_term_string"
                }
              ],
              inLanguage: "en-US"
            },
            {
              "@type": "WebPage",
              "@id": "https://www.tectratechnologies.com/contact/",
              url: "https://www.tectratechnologies.com/contact/",
              name: "Contact Us - Tectra Technologies",
              isPartOf: {
                "@id": "https://www.tectratechnologies.com/#website"
              },
              datePublished: "2019-06-18T09:29:45+00:00",
              dateModified: "2022-07-28T06:10:07+00:00",
              description:
                "Tectra Technologies produces and delivers the right solution to a vast array of demands in the digital atmosphere and in software development",
              breadcrumb: {
                "@id": "https://www.tectratechnologies.com/contact/#breadcrumb"
              },
              inLanguage: "en-US",
              potentialAction: [
                {
                  "@type": "ReadAction",
                  target: ["https://www.tectratechnologies.com/contact/"]
                }
              ]
            },
            {
              "@type": "BreadcrumbList",
              "@id":
                "https://www.tectratechnologies.com/contact-us/#breadcrumb",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Home",
                  item: "https://www.tectratechnologies.com/"
                },
                { "@type": "ListItem", position: 2, name: "Contact Us" }
              ]
            }
          ]
        }}
      />
      <Layout>
        <div className="bg-white mt-28 lg:mt-32">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-center text-3xl font-poppins font-semibold captalize tracking-tight">
              Let's connect
            </h2>
            <p className="text-lg text-center font-poppins font-thin mt-4">
              Find the most convenient way to get in touch with us.
            </p>
          </div>
        </div>

        <div className="bg-transparent mt-10 lg:mt-16">
          <div className="w-full">
            <StaticImage
              src="../images/contact-hero.png"
              quality={95}
              height={700}
              className="w-full h-60 md:h-92 lg:h-auto"
              formats={["auto", "png"]}
              alt=""
            />
          </div>
        </div>

        <div className="bg-transparent my-10 lg:my-24">
          <div className="max-w-7xl w-full mx-auto px-5 lg:px-8">
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-12">
              <div className="col-span-1 rounded-2xl bg-gray-100 p-5 lg:p-10 text-center">
                <h4 className="mt-3 text-xl font-poppins font-medium">
                  Tectra Technologies LLC
                </h4>
                <div className="mt-2 text-lg font-poppins font-thin leading-8">
                  30 N Gould ST Ste R Sheridan,
                  <br />
                  WY 82801. USA
                </div>
              </div>
              <div className="col-span-1 rounded-2xl bg-gray-100 p-5 lg:p-10 text-center">
                <h4 className="mt-3 text-xl font-poppins font-medium">
                  Tectra Tech Sdn Bhd
                </h4>
                <div className="mt-2 text-lg font-poppins font-thin">
                  B-5-8, Plaza Mont Kiara, <br />
                  Mont Kiara 50480, <br />
                  Kuala Lumpur, Malaysia
                </div>
              </div>
              <div className="col-span-1 rounded-2xl bg-gray-100 p-5 lg:p-10 text-center">
                <h4 className="mt-3 text-xl font-poppins font-medium">
                  Tectra Technologies
                </h4>
                <div className="mt-2 text-lg font-poppins font-thin leading-8">
                  W-126, 3rd Floor, 3rd Avenue, <br />
                  Anna Nagar, Chennai – 600040
                </div>
              </div>
              {/* <div className="col-span-1 rounded-2xl bg-gray-100 p-5 lg:p-10 text-center">
                <h4 className="mt-3 text-xl font-poppins font-medium">
                  Tectra Tech Ltd
                </h4>
                <div className="mt-2 text-lg font-poppins font-thin leading-8">
                  85, Great Portland Street, London, <br />
                  England W1W 7LT
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ContactPage;
