import React, { useState, useEffect, useRef } from "react";

interface Stat {
  value: number;
  suffix: string;
  label: string;
}

const stats: Stat[] = [
  { value: 100, suffix: "+", label: "Projects Completed" },
  { value: 40, suffix: "%", label: "Retention Boost" },
  { value: 7, suffix: "+", label: "Years in Blockchain" },
];

const StatsSection: React.FC = () => {
  const [counters, setCounters] = useState<number[]>(stats.map(() => 0));
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.3 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const intervals = stats.map((stat, index) => {
        const increment = stat.value / 100;
        return setInterval(() => {
          setCounters((prev) => {
            const newCounters = [...prev];
            if (newCounters[index] < stat.value) {
              newCounters[index] = Math.min(
                stat.value,
                newCounters[index] + increment
              );
            }
            return newCounters;
          });
        }, 30);
      });

      return () => intervals.forEach((interval) => clearInterval(interval));
    }
  }, [isVisible]);

  return (
    <div
      ref={sectionRef}
      className="flex flex-col md:flex-row justify-start gap-20 items-baseline py-10 mt-10"
    >
      {stats.map((stat, index) => (
        <div key={index} className="text-left bg-gray-200 p-6 rounded-md w-52">
          <h3 className="text-5xl font-bold text-black">
            {Math.floor(counters[index])}
            {stat.suffix}
          </h3>
          <p className="mt-2 text-lg font-semibold text-gray-900">
            {stat.label}
          </p>
        </div>
      ))}
    </div>
  );
};

export default StatsSection;
