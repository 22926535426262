import { Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Arrow } from "@/components/arrow";
import { Layout } from "@/components/layout";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";
import StatsSection from "@/components/StatsSection";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import CaseStudies from "@/components/CaseStudies";

const AboutUsPage = () => {
  return (
    <>
      <GatsbySeo
        title="Blockchain | Tectra Technologies"
        description="We, at Tectra Technologies, collaborate with entrepreneurs and business leaders to bring transformative ideas to reality using different technologies. As a pioneer in the software development market, we cater to the diverse needs of traditional as well as digital businesses while emphasizing performance, accessibility, reliability, and quality. All our specialized services like web development, mobile application development, UI/UX design, cloud computing, DevOps, Quality Assurance, etc. are customized as per varied needs and specifications of business verticals."
        canonical="https://www.tectratechnologies.com/blockchain/"
        openGraph={{
          locale: "en_US",
          type: "article",
          url: "https://www.tectratechnologies.com/blockchain/",
          title: "Blockchain - Tectra Technologies",
          description:
            "We, at Tectra Technologies, collaborate with entrepreneurs and business leaders to bring transformative ideas to reality using different technologies. As a pioneer in the software development market, we cater to the diverse needs of traditional as well as digital businesses while emphasizing performance, accessibility, reliability, and quality. All our specialized services like web development, mobile application development, UI/UX design, cloud computing, DevOps, Quality Assurance, etc. are customized as per varied needs and specifications of business verticals.",
          site_name: "Tectra Technologies",
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
            {
              "@type": "WebPage",
              "@id": "https://www.tectratechnologies.com/blockchain/",
              url: "https://www.tectratechnologies.com/blockchain/",
              name: "Blockchain - Tectra Technologies",
              isPartOf: {
                "@id": "https://www.tectratechnologies.com/#website",
              },
              datePublished: "2019-06-18T09:29:45+00:00",
              dateModified: "2022-07-28T06:10:07+00:00",
              description:
                "We, at Tectra Technologies, collaborate with entrepreneurs and business leaders to bring transformative ideas to reality using different technologies. As a pioneer in the software development market, we cater to the diverse needs of traditional as well as digital businesses while emphasizing performance, accessibility, reliability, and quality. All our specialized services like web development, mobile application development, UI/UX design, cloud computing, DevOps, Quality Assurance, etc. are customized as per varied needs and specifications of business verticals.",
              breadcrumb: {
                "@id":
                  "https://www.tectratechnologies.com/contact-us/#breadcrumb",
              },
              inLanguage: "en-US",
              potentialAction: [
                {
                  "@type": "ReadAction",
                  target: ["https://www.tectratechnologies.com/blockchain/"],
                },
              ],
            },
            {
              "@type": "BreadcrumbList",
              "@id":
                "https://www.tectratechnologies.com/contact-us/#breadcrumb",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Home",
                  item: "https://www.tectratechnologies.com/",
                },
                { "@type": "ListItem", position: 2, name: "Blockchain" },
              ],
            },
          ],
        }}
      />
      <Layout>
        <div className="bg-white py-20">
          <div className="w-full max-w-7xl mx-auto px-5 lg:px-8">
            <div className="text-left mt-8 xl:mt-24 xl:w-2/5">
              <div className="pt-0">
                <h1 className="text-black text-4xl font-poppins font-semibold mb-4 capitalize">
                  Transforming Ideas into Blockchain Reality
                </h1>
                <p className="text-lg  text-gray-900 font-poppins font-medium leading-6">
                  Solutions for Tokenomics, NFTs, Smart Contracts, Mini-games
                  and Beyond.
                </p>
                <div className="relative flex overflow-hidden justify-start items-center text-center mt-4">
                  <Link
                    to="/contact"
                    className="flex items-center text-xl capitalize hover:text-black"
                    activeClassName="border-b-2 border-black"
                  >
                    Talk to Us
                    <Arrow color="black" />
                  </Link>
                </div>
              </div>
            </div>
            <StatsSection />
          </div>
        </div>

        <div className="bg-transparent">
          <div className="w-full">
            <StaticImage
              src="../images/blockchain1.png"
              quality={95}
              height={700}
              className="w-full h-60 md:h-92 lg:h-auto"
              formats={["auto", "png"]}
              alt="Tectra Technologies"
            />
          </div>
        </div>

        <div className="bg-black ">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <div className="py-10 lg:py-20">
              <h2 className="text-white text-4xl font-poppins font-semibold mb-4 capitalize">
                Who We Are
              </h2>
              <p className="text-lg  text-white font-poppins font-thin leading-8">
                We are a leading blockchain solutions provider, specializing in
                crafting scalable, secure, and innovative systems tailored for
                the decentralized future. Our mission is to empower businesses
                to unlock the full potential of blockchain technology.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white ">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <div className="py-10 lg:py-20">
              <h2 className="text-black text-center text-4xl font-poppins font-semibold mb-4 capitalize">
                Our Services
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
                <div className="bg-[#F7F7F7] px-10 py-16 flex flex-col gap-2 items-baseline">
                  <StaticImage
                    src="../images/block-icon-1.png"
                    height={60}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="Tokenomics"
                  />
                  <h3 className="text-black  text-xl font-poppins font-semibold capitalize">
                    Tokenomics
                  </h3>
                  <p className="text-lg  text-gray-900 font-poppins font-thin leading-6">
                    Comprehensive token economy design for sustainable projects.
                  </p>
                </div>
                <div className="bg-[#F7F7F7] px-10 py-16 flex flex-col gap-2 items-baseline">
                  <StaticImage
                    src="../images/block-icon-2.png"
                    height={60}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="Tokenomics"
                  />
                  <h3 className="text-black text-xl font-poppins font-semibold capitalize">
                    NFT Design
                  </h3>
                  <p className="text-lg  text-gray-900 font-poppins font-thin leading-6">
                    Engaging and market-ready NFT collections.
                  </p>
                </div>
                <div className="bg-[#F7F7F7] px-10 py-16 flex flex-col gap-2 items-baseline">
                  <StaticImage
                    src="../images/block-icon-3.png"
                    height={60}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="Tokenomics"
                  />
                  <h3 className="text-black  text-xl font-poppins font-semibold capitalize">
                    Meme Projects
                  </h3>
                  <p className="text-lg  text-gray-900 font-poppins font-thin leading-6">
                    Mascots and narratives that boost brand engagement.
                  </p>
                </div>
                <div className="bg-[#F7F7F7] px-10 py-16 flex flex-col gap-2 items-baseline">
                  <StaticImage
                    src="../images/block-icon-4.png"
                    height={60}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="Tokenomics"
                  />
                  <h3 className="text-black  text-xl font-poppins font-semibold capitalize">
                    NFT Marketplaces
                  </h3>
                  <p className="text-lg  text-gray-900 font-poppins font-thin leading-6">
                    Consulting for building user-friendly NFT trading platforms.
                  </p>
                </div>
                <div className="bg-[#F7F7F7] px-10 py-16 flex flex-col gap-2 items-baseline">
                  <StaticImage
                    src="../images/block-icon-5.png"
                    height={60}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="Tokenomics"
                  />
                  <h3 className="text-black text-xl font-poppins font-semibold capitalize">
                    Smart Contracts
                  </h3>
                  <p className="text-lg  text-gray-900 font-poppins font-thin leading-6">
                    Secure and efficient smart contract solutions.
                  </p>
                </div>
                <div className="bg-[#F7F7F7] px-10 py-16 flex flex-col gap-2 items-baseline">
                  <StaticImage
                    src="../images/block-icon-6.png"
                    height={60}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="Tokenomics"
                  />
                  <h3 className="text-black  text-xl font-poppins font-semibold capitalize">
                    Questing Platforms
                  </h3>
                  <p className="text-lg  text-gray-900 font-poppins font-thin leading-6">
                    Gamified systems to increase user retention.
                  </p>
                </div>
                <div className="bg-[#F7F7F7] px-10 py-16 flex flex-col gap-2 items-baseline">
                  <StaticImage
                    src="../images/block-icon-7.png"
                    height={60}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="Tokenomics"
                  />
                  <h3 className="text-black  text-xl font-poppins font-semibold capitalize">
                    Telegram Mini-Games
                  </h3>
                  <p className="text-lg  text-gray-900 font-poppins font-thin leading-6">
                    Fun, interactive mini-games to engage your community.
                  </p>
                </div>
                <div className="bg-[#F7F7F7] px-10 py-16 flex flex-col gap-2 items-baseline">
                  <StaticImage
                    src="../images/block-icon-8.png"
                    height={60}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="Tokenomics"
                  />
                  <h3 className="text-black  text-xl font-poppins font-semibold capitalize">
                    Exchange Development
                  </h3>
                  <p className="text-lg  text-gray-900 font-poppins font-thin leading-6">
                    Robust and user-focused trading platforms.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-black">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <div className="py-10 lg:py-20">
              <h2 className="text-white text-4xl text-center font-poppins font-semibold mb-4 capitalize">
                Our Approach
              </h2>
              <div className="hidden md:block">
                <div className="mt-10 ml-2 lg:ml-6">
                  <ul className="font-poppins sm:flex">
                    <li className="relative mb-6 sm:mb-0">
                      <div className="flex items-center">
                        <div className="flex z-10 justify-center items-center w-6 h-6 bg-white rounded-full flex-shrink-0"></div>
                        <div className="hidden sm:flex w-full bg-white h-0.5 dark:bg-white"></div>
                      </div>
                      <div className="mt-3 sm:pr-8">
                        <h3 className="text-2xl font-poppins font-semibold text-white">
                          Discover
                        </h3>
                        <p className="text-lg  text-white font-poppins font-thin leading-6 mt-1">
                          Understand your vision and goals.
                        </p>
                      </div>
                    </li>
                    <li className="relative mb-6 sm:mb-0">
                      <div className="flex items-center">
                        <div className="flex z-10 justify-center items-center w-6 h-6 bg-white rounded-full flex-shrink-0"></div>
                        <div className="hidden sm:flex w-full bg-white h-0.5 dark:bg-white"></div>
                      </div>
                      <div className="mt-3 sm:pr-8">
                        <h3 className="text-2xl font-poppins font-semibold text-white">
                          Design
                        </h3>
                        <p className="text-lg  text-white font-poppins font-thin leading-6 mt-1">
                          Develop customized strategies & designs.
                        </p>
                      </div>
                    </li>
                    <li className="relative mb-6 sm:mb-0">
                      <div className="flex items-center">
                        <div className="flex z-10 justify-center items-center w-6 h-6 bg-white rounded-full flex-shrink-0"></div>
                        <div className="hidden sm:flex w-full bg-white h-0.5 dark:bg-white"></div>
                      </div>
                      <div className="mt-3 sm:pr-8">
                        <h3 className="text-2xl font-poppins font-semibold text-white">
                          Develop
                        </h3>
                        <p className="text-lg  text-white font-poppins font-thin leading-6 mt-1">
                          Build secure, scalable, and innovative blockchain
                          solutions.
                        </p>
                      </div>
                    </li>
                    <li className="relative mb-6 sm:mb-0">
                      <div className="flex items-center">
                        <div className="flex z-10 justify-center items-center w-6 h-6 bg-white rounded-full flex-shrink-0"></div>
                      </div>
                      <div className="mt-3 sm:pr-8">
                        <h3 className="text-2xl font-poppins font-semibold text-white">
                          Deploy
                        </h3>
                        <p className="text-lg  text-white font-poppins font-thin leading-6 mt-1">
                          Seamlessly integrate and launch with ongoing support.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="md:hidden">
                <div className="mt-6 ml-2 lg:ml-0">
                  <ul className="relative border-l border-gray-300">
                    <li className="mb-10 ml-6">
                      <div className="absolute w-4 h-4 bg-white rounded-full mt-1.5 -left-2 border border-white"></div>
                      <h3 className="text-xl font-poppins font-semibold text-white">
                        Discover
                      </h3>
                      <p className="text-white text-lg">
                        Understand your vision and goals.
                      </p>
                    </li>
                    <li className="mb-10 ml-6">
                      <div className="absolute w-4 h-4 bg-white rounded-full mt-1.5 -left-2 border border-white"></div>
                      <h3 className="text-xl font-poppins font-semibold text-white">
                        Design
                      </h3>
                      <p className="text-white text-lg">
                        Develop customized strategies & designs.
                      </p>
                    </li>
                    <li className="mb-10 ml-6">
                      <div className="absolute w-4 h-4 bg-white rounded-full mt-1.5 -left-2 border border-white"></div>
                      <h3 className="text-xl font-poppins font-semibold text-white">
                        Develop
                      </h3>
                      <p className="text-white text-lg">
                        Build secure, scalable, and innovative blockchain
                        solutions.
                      </p>
                    </li>
                    <li className="mb-10 ml-6">
                      <div className="absolute w-4 h-4 bg-white rounded-full mt-1.5 -left-2 border border-white"></div>
                      <h3 className="text-xl font-poppins font-semibold text-white">
                        Deploy
                      </h3>
                      <p className="text-white text-lg">
                        Seamlessly integrate and launch with ongoing support.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-transparent">
          <div className="w-full">
            <StaticImage
              src="../images/blockchain2.png"
              quality={95}
              height={700}
              className="w-full h-60 md:h-92 lg:h-auto"
              formats={["auto", "png"]}
              alt="Tectra Technologies"
            />
          </div>
        </div>
        <div className="bg-white ">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <div className="py-10 lg:py-20">
              <h2 className="text-black text-center text-4xl font-poppins font-semibold mb-4 capitalize">
                Case studies
              </h2>
              <CaseStudies />
            </div>
          </div>
        </div>
        <div className="bg-white ">
          <div className="max-w-7xl mx-auto ">
            <div className="pb-10 lg:pb-20">
              <div className="mt-6 lg:mt-12 px-5 lg:px-0">
                <Swiper
                  slidesPerView={4}
                  spaceBetween={20}
                  navigation={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Navigation, Autoplay]}
                  breakpoints={{
                    360: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                  }}
                >
                  <SwiperSlide>
                    <div className="flex flex-col justify-center font-poppins font-normal rounded">
                      <div className="w-full">
                        <StaticImage
                          src="../images/block-slide-1.png"
                          width={400}
                          quality={95}
                          formats={["auto", "png"]}
                          alt="Website Design and Development"
                          className="w-full"
                          loading="eager"
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col justify-center font-poppins font-normal rounded">
                      <div className="w-full">
                        <StaticImage
                          src="../images/block-slide-2.png"
                          width={400}
                          quality={95}
                          formats={["auto", "png"]}
                          alt="Website Design and Development"
                          className="w-full"
                          loading="eager"
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col justify-center font-poppins font-normal rounded">
                      <div className="w-full">
                        <StaticImage
                          src="../images/block-slide-3.png"
                          width={400}
                          quality={95}
                          formats={["auto", "png"]}
                          alt="Website Design and Development"
                          className="w-full"
                          loading="eager"
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col justify-center font-poppins font-normal rounded">
                      <div className="w-full">
                        <StaticImage
                          src="../images/block-slide-4.png"
                          width={400}
                          quality={95}
                          formats={["auto", "png"]}
                          alt="Website Design and Development"
                          className="w-full"
                          loading="eager"
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col justify-center font-poppins font-normal rounded">
                      <div className="w-full">
                        <StaticImage
                          src="../images/block-slide-5.png"
                          width={400}
                          quality={95}
                          formats={["auto", "png"]}
                          alt="Website Design and Development"
                          className="w-full"
                          loading="eager"
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col justify-center font-poppins font-normal rounded">
                      <div className="w-full">
                        <StaticImage
                          src="../images/block-slide-6.png"
                          width={400}
                          quality={95}
                          formats={["auto", "png"]}
                          alt="Website Design and Development"
                          className="w-full"
                          loading="eager"
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col justify-center font-poppins font-normal rounded">
                      <div className="w-full">
                        <StaticImage
                          src="../images/block-slide-7.png"
                          width={400}
                          quality={95}
                          formats={["auto", "png"]}
                          alt="Website Design and Development"
                          className="w-full"
                          loading="eager"
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col justify-center font-poppins font-normal rounded">
                      <div className="w-full">
                        <StaticImage
                          src="../images/block-slide-8.png"
                          width={400}
                          quality={95}
                          formats={["auto", "png"]}
                          alt="Website Design and Development"
                          className="w-full"
                          loading="eager"
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col justify-center font-poppins font-normal rounded">
                      <div className="w-full">
                        <StaticImage
                          src="../images/block-slide-9.png"
                          width={400}
                          quality={95}
                          formats={["auto", "png"]}
                          alt="Website Design and Development"
                          className="w-full"
                          loading="eager"
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AboutUsPage;
