import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

interface CaseStudy {
  id: number;
  title: string;
  solutionHighlights: string[];
  outcome: string;
}

const CaseStudies: React.FC = () => {
  const [activePopup, setActivePopup] = useState<number | null>(null);

  const caseStudies: CaseStudy[] = [
    {
      id: 1,
      title: "Boosting Retention for a Web3 Game",
      solutionHighlights: [
        "Designed gamified UX with progressive rewards to motivate continued engagement.",
        "Incorporated achievement badges, leaderboards, and team-based rewards.",
        "Integrated a feedback loop to understand player preferences.",
      ],
      outcome:
        "Retention rate increased by 47% within six months, resulting in a thriving player community and extended user sessions.",
    },
    {
      id: 2,
      title: "Growing Wallet Users with Mini-Games",
      solutionHighlights: [
        "Developed mini-games with tokenized rewards tied to wallet transactions.",
        "Organized community competitions to spark interest.",
        "Launched localized media campaigns aligned with the wallet's partner blockchain.",
      ],
      outcome:
        "Achieved significant spikes in wallet registrations and activity, resulting in increased transactions on the client's partner blockchain.",
    },
    {
      id: 3,
      title: "Enhancing NFT Marketplace Features",
      solutionHighlights: [
        "Introduced gamified systems allowing users to earn points for NFT purchases and engagement.",
        "Designed custom algorithms to recommend NFTs based on user behavior.",
        "Executed user acquisition strategies, including media-based partnerships.",
      ],
      outcome:
        "Enhanced user experience and increased platform transactions, establishing the marketplace as a competitive player in the space.",
    },
  ];

  return (
    <div className="space-y-8 mt-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Map through the case studies */}
        {caseStudies.map((study) => (
          <div
            key={study.id}
            className="relative group cursor-pointer"
            onClick={() => setActivePopup(study.id)}
          >
            {study.id === 1 && (
              <StaticImage
                src="../images/block-case-1.png"
                quality={100}
                height={433}
                className="w-full h-full md:h-full lg:h-auto"
                formats={["auto", "png"]}
                alt="Boosting Retention"
              />
            )}
            {study.id === 2 && (
              <StaticImage
                src="../images/block-case-2.png"
                quality={100}
                height={433}
                className="w-full h-full md:h-full lg:h-auto"
                formats={["auto", "png"]}
                alt="Growing Wallet Users"
              />
            )}
            {study.id === 3 && (
              <StaticImage
                src="../images/block-case-3.png"
                quality={100}
                height={433}
                className="w-full h-full md:h-full lg:h-auto"
                formats={["auto", "png"]}
                alt="Enhancing NFT Features"
              />
            )}
            <div className="absolute inset-0  rounded-lg flex items-end justify-center bottom-4">
              <p className="text-white text-xl font-semibold">{study.title}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Popups */}
      {activePopup && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-black rounded-lg max-w-xl w-full p-6 relative">
            <button
              onClick={() => setActivePopup(null)}
              className="absolute top-4 right-4 text-white font-bold text-lg"
            >
              &times;
            </button>
            <h3 className="text-xl font-bold mb-4 text-white">
              {caseStudies.find((study) => study.id === activePopup)?.title}
            </h3>
            <h4 className="font-semibold mb-2 text-white">
              Solution Highlights:
            </h4>
            <ul className="list-disc pl-5 space-y-1 text-white">
              {caseStudies
                .find((study) => study.id === activePopup)
                ?.solutionHighlights.map((highlight, index) => (
                  <li key={index} className="text-white">
                    {highlight}
                  </li>
                ))}
            </ul>
            <h4 className="font-semibold mt-4 mb-2 text-white">Outcome:</h4>
            <p className="text-white">
              {caseStudies.find((study) => study.id === activePopup)?.outcome}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CaseStudies;
