exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blockchain-tsx": () => import("./../../../src/pages/blockchain.tsx" /* webpackChunkName: "component---src-pages-blockchain-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-blogs-mdx-slug-tsx": () => import("./../../../src/pages/blogs/{mdx.slug}.tsx" /* webpackChunkName: "component---src-pages-blogs-mdx-slug-tsx" */),
  "component---src-pages-careers-business-analyst-tsx": () => import("./../../../src/pages/careers/business-analyst.tsx" /* webpackChunkName: "component---src-pages-careers-business-analyst-tsx" */),
  "component---src-pages-careers-business-development-tsx": () => import("./../../../src/pages/careers/business-development.tsx" /* webpackChunkName: "component---src-pages-careers-business-development-tsx" */),
  "component---src-pages-careers-flutter-developer-tsx": () => import("./../../../src/pages/careers/flutter-developer.tsx" /* webpackChunkName: "component---src-pages-careers-flutter-developer-tsx" */),
  "component---src-pages-careers-fullstack-enginner-tsx": () => import("./../../../src/pages/careers/fullstack-enginner.tsx" /* webpackChunkName: "component---src-pages-careers-fullstack-enginner-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-careers-php-developer-tsx": () => import("./../../../src/pages/careers/php-developer.tsx" /* webpackChunkName: "component---src-pages-careers-php-developer-tsx" */),
  "component---src-pages-case-studies-carpenters-finance-tsx": () => import("./../../../src/pages/case-studies/carpenters-finance.tsx" /* webpackChunkName: "component---src-pages-case-studies-carpenters-finance-tsx" */),
  "component---src-pages-case-studies-first-break-tsx": () => import("./../../../src/pages/case-studies/first-break.tsx" /* webpackChunkName: "component---src-pages-case-studies-first-break-tsx" */),
  "component---src-pages-case-studies-mothrly-tsx": () => import("./../../../src/pages/case-studies/mothrly.tsx" /* webpackChunkName: "component---src-pages-case-studies-mothrly-tsx" */),
  "component---src-pages-case-studies-red-alert-tsx": () => import("./../../../src/pages/case-studies/red-alert.tsx" /* webpackChunkName: "component---src-pages-case-studies-red-alert-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-cloud-computing-tsx": () => import("./../../../src/pages/services/cloud-computing.tsx" /* webpackChunkName: "component---src-pages-services-cloud-computing-tsx" */),
  "component---src-pages-services-dev-ops-solutions-tsx": () => import("./../../../src/pages/services/dev-ops-solutions.tsx" /* webpackChunkName: "component---src-pages-services-dev-ops-solutions-tsx" */),
  "component---src-pages-services-digital-360-tsx": () => import("./../../../src/pages/services/digital-360.tsx" /* webpackChunkName: "component---src-pages-services-digital-360-tsx" */),
  "component---src-pages-services-mobile-app-development-tsx": () => import("./../../../src/pages/services/mobile-app-development.tsx" /* webpackChunkName: "component---src-pages-services-mobile-app-development-tsx" */),
  "component---src-pages-services-qa-automation-tsx": () => import("./../../../src/pages/services/qa-automation.tsx" /* webpackChunkName: "component---src-pages-services-qa-automation-tsx" */),
  "component---src-pages-services-ui-ux-design-tsx": () => import("./../../../src/pages/services/ui-ux-design.tsx" /* webpackChunkName: "component---src-pages-services-ui-ux-design-tsx" */),
  "component---src-pages-services-web-app-development-tsx": () => import("./../../../src/pages/services/web-app-development.tsx" /* webpackChunkName: "component---src-pages-services-web-app-development-tsx" */)
}

